@import '@mediature/main/src/app/workarounds.scss';

html,
body {
  width: 100%;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}
